
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';

import Vue from 'vue';
import { CreateCarData, ICarViewModel } from 'dexxter-types';
import ServiceUnavailable from '@/components/ServiceUnavailable.vue';
import { createCarData } from '../model';
import CarTable from './components/CarTable.vue';
import TableCard from '@/components/TableCard.vue';
import CarModal from '../create/components/CarModal.vue';
import { DeepNullable } from '../../../../../helpers/types';

export default Vue.extend({
    components: {
        ViewLayout,
        PageHeader,
        ServiceUnavailable,
        CarTable,
        TableCard,
        CarModal
    },

    data() {
        return {
            cars: [] as ICarViewModel[],
            loading: false,
            serviceUnavailable: false,
            openCarModal: false,
            carData: null as null | DeepNullable<CreateCarData>
        };
    },

    computed: {
        isServiceUnavailable(): boolean {
            return this.serviceUnavailable;
        }
    },

    methods: {
        openCreateCarPopup() {
            this.carData = createCarData();
            this.openCarModal = true;
        },
        reloadData() {
            if (this.$refs.carTable) {
                (this.$refs.carTable as any).wrapperFunction();
            }
        }
    }
});
